<template>
  <div class="cash-request-list">
    <error :errors="entrustError" />
    <b-overlay :show="entrustBusy" rounded="sm">
      <portlet fluidHeight foot-class="p-4">
        <template v-slot:body>
          <div class="kt-widget kt-widget--user-profile-3">
            <div class="kt-widget__top">
              <div class="kt-widget__media">
                <b-avatar :src="user.avatar" text="!" size="100px" rounded="" />
              </div>
              <div class="kt-widget__content">
                <div class="kt-widget__head">
                  <div class="kt-widget__user">
                    <a href="javascript:;" class="kt-widget__username">{{ user.nickname ? user.nickname : 'نامەلۇم' }}</a>
                    <span class="kt-badge kt-badge--bolder kt-badge--inline kt-badge--unified-success cursor-default mx-1 text-nowrap">{{ entrust.user && entrust.user.is_super_inviter ? 'ۋاكالەتچى' : 'ئادەتتىكى ئەزا'}}</span>
                  </div>
                </div>
                <div class="kt-widget__info mt-3 flex-column">
                  <div class="kt-widget__desc direction-rtl">
                    <a v-b-tooltip title="ئالىي ئەزالىق ۋاقتى" class="cursor-default text-black-50" ><i class="fa fa-user-circle ml-2"></i>{{ user.vip_expires_in ? user.vip_expires_in : 'نامەلۇم' }} </a>
                  </div>
                  <div class="kt-widget__desc direction-rtl">
                    <a v-b-tooltip title="كېلەر نۆۋەتلىك پۇل تۇتۇش ۋاقتى" class="cursor-default text-black-50" ><i class="fa fa-clock ml-2"></i>{{ entrust.next_time }} </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-widget__bottom"></div>
            <b-row class="row-no-padding row-col-separator-lg">
              <b-col sm="12" md="4">
                <div class="kt-widget24">
                  <div class="kt-widget24__details">
                    <div class="kt-widget24__info">
                      <h4 class="kt-widget24__title">مۇۋاپىقىيەتلىك زاكاز</h4>
                      <span class="kt-widget24__desc">مۇۋاپىقىيەتلىك پۇل كولاش قېتىم سانى</span>
                    </div>
                    <span class="kt-widget24__stats kt-font-brand">{{ entrust.success_orders }} قېتىم</span>
                  </div>
                  <b-progress size="sm">
                    <b-progress-bar :value="success_order_progress" variant="primary"></b-progress-bar>
                  </b-progress>
                  <div class="kt-widget24__action">
                    <span class="kt-widget24__change">ئۇمۇمىي زاكاز</span>
                    <span class="kt-widget24__number">{{ entrust.orders }} دانە</span>
                  </div>
                </div>
              </b-col>
              <b-col sm="12" md="4">
                <div class="kt-widget24">
                  <div class="kt-widget24__details">
                    <div class="kt-widget24__info">
                      <h4 class="kt-widget24__title">زاكاز سوممىسى</h4>
                      <span class="kt-widget24__desc">ھازىرغىچە تۇتۇلغان سومما</span>
                    </div>
                    <span class="kt-widget24__stats kt-font-success">{{ entrust.order_total_fee_sum }} يۈەن</span>
                  </div>
                  <b-progress size="sm">
                    <b-progress-bar :value="entrust.order_total_fee_sum" :max="entrust.order_total_fee_sum" variant="success"></b-progress-bar>
                  </b-progress>
                  <div class="kt-widget24__action">
                    <span class="kt-widget24__change">ئۇمۇمىي سومما</span>
                    <span class="kt-widget24__number">{{ entrust.order_total_fee_sum }} يۈەن</span>
                  </div>
                </div>
              </b-col>
              <b-col sm="12" md="4">
                <div class="kt-widget24">
                  <div class="kt-widget24__details">
                    <div class="kt-widget24__info">
                      <h4 class="kt-widget24__title">مەغلۇپ بولغان زاكاز</h4>
                      <span class="kt-widget24__desc">پۇل تۇتالمىغان زاكازلار</span>
                    </div>
                    <span class="kt-widget24__stats kt-font-danger"> {{ entrust.fail_orders }} قېتىم</span>
                  </div>
                  <b-progress size="sm">
                    <b-progress-bar :value="fail_order_progress" variant="danger"></b-progress-bar>
                  </b-progress>
                  <div class="kt-widget24__action">
                    <span class="kt-widget24__change">ئۇمۇمىي زاكاز</span>
                    <span class="kt-widget24__number">{{ entrust.orders }} دانە</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="foot">
          <div class="footer-area">
            <b-link class="kt-badge kt-badge--bolder kt-badge--inline kt-badge--unified-success mx-1" :to="{name: 'wallet.show', params: {user_id: invitor.id}}" v-if="invitor.id"> <i class="fa fa-portrait mr-2"></i> تەشۋىقاتچىغا ئاتلاش </b-link>
            <b-link class="kt-badge kt-badge--bolder kt-badge--inline kt-badge--unified-danger mx-1" @click="onClickDeleteContract(entrust.id)"> <i class="fa fa-unlink mr-2"></i> كېلىشىمنى بىكار قىلىش </b-link>
          </div>
        </template>
      </portlet>
      <paginate :meta="entrustOrdersMeta" :implement-search="false" @change-page="onChangePage" @refresh="onRefresh" />
      <b-row>
        <b-col v-for="order in entrustOrders" :key="order.id" md="3" sm="6">
          <portlet body-fluid body-class="px-3 pb-0 pt-0" headNoBorder head-class="px-2" head-size="sm" foot-class="p-2">
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> ھالىتى </span>
                        <span class="kt-widget__data">{{ order.trade_state != 'SUCCESS' ? 'پۇل تۇتۇلمىغان' : 'پۇل تۇتۇلغان'}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كولانغان سومما</span>
                        <span class="kt-widget__data direction-rtl">{{ order.total_fee ? order.total_fee + ' يۈەن ' : 'تۇتۇلمىغان' }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">ئىلتىماس قىلغان ۋاقىت</span>
                        <span class="kt-widget__data direction-rtl">{{ order.created_at }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">نەتىجىسى كەلگەن ۋاقىت</span>
                        <span class="kt-widget__data direction-rtl">{{ order.trade_state === 'ACCEPT' ? 'نەتىجىسى كەلمىدى' : order.updated_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="foot">
<!--              <p class="text-right text-danger cursor-default direction-rtl mb-0" v-if="order.resource">{{ order.resource.err_code_des }}</p>-->
              <p class="text-right text-danger cursor-default direction-rtl mb-0" v-if="order.resource">{{ formatResource(order.resource) }}</p>
              <p class="mb-0 text-success cursor-default" v-if="order.trade_state === 'SUCCESS'">{{ order.total_fee === 0 ? 'تۇنجى قېتىم ھەقسىز' : 'نورمال پۇل تۇتۇلغان'}}</p>
              <p class="mb-0 text-success cursor-default" v-if="order.trade_state === 'ACCEPT'">پۇل كولاش ئىلتىماسى يوللانغان، 24 سائەتتىن كىيىن نەتىجىسىنى كۆرۈڭ</p>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import entrustMixin from  "@m@/entrust.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, paginate },
    mixins: [ formBusyMixin, entrustMixin, msgBoxConfirmMixin, sweetAlertMixin ],
    created() {
      this.getEntrust(this.entrustId);
      this.$nextTick(()=>{
        this.getEntrustOrder(this.entrustId);
      });
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'كېلىشىم ئەزالار', route: {name: 'entrust.home'}});
      BreadCrumbs.addBreadCrumb({ title: "كېلىشىم تەپسىلاتى" });
    },
    computed: {
      entrustId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.entrust_id;
      },
      user() {
        return this.entrust.hasOwnProperty('user') ? this.entrust.user : {}
      },
      invitor(){
        if ( this.entrust.hasOwnProperty('user') && this.entrust.user ){
          if ( this.entrust.user.hasOwnProperty('invitor') && this.entrust.user.invitor ){
            return this.entrust.user.hasOwnProperty('invitor') ? this.entrust.user.invitor : {}
          }
        }
        return {};
      },
      success_order_progress(){
        let successOrders = this.entrust.success_orders;
        let orders = this.entrust.orders;
        return (successOrders / orders) * 100;
      },
      fail_order_progress(){
        let failOrders = this.entrust.fail_orders;
        let orders = this.entrust.orders;
        return (failOrders / orders) * 100;
      },
    },
    data(){
      return {
        entrustBusy: false,
      };
    },
    methods: {
      onChangePage({page = 1}){
        this.getEntrustOrder(this.entrustId, page);
      },
      onRefresh(){
        this.getEntrustOrder(this.entrustId);
      },
      formatResource(resource){
        if (typeof resource === 'string'){
          let r = JSON.parse(resource);
          if (r.hasOwnProperty('err_code_des')){
            return r.err_code_des;
          }
          return null;
        }else if ( typeof resource === 'object'){
          if (resource.hasOwnProperty('err_code_des')){
            return resource.err_code_des;
          }
          return null;
        }
        return null;
      },
      onClickDeleteContract(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر كېلىشىمنى بىكار قىلىشنى جەزىملەشتۈرەمسىز ؟كېلىشىمنى بىكار قىلسىڭىز مەزكۇر ئەزادىن پۇل تۇتمايدۇ، خۇددى شۇ ئەزا ئۆز ئالدىغا بىكار قىلغان بىلەن ئوخشاش كۈچكە ئېگە!',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.manualDeleteContract(id)
                      .then(()=>{
                        this.success({message: 'كېلىشىمنى بىكار قىلىش مۇۋاپىقىيەتلىك بولدى!'})
                          .then(()=>{
                            this.$router.replace({name: 'entrust.home'});
                          })
                      })
                      .catch((result)=>{
                        console.log(result.response);
                        let { message } = result.response.data;
                        this.error({message});
                      })
            }
          }
        });
      }
    }
  }
</script>

<style>

</style>
