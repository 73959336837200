<template>
  <div class="entrust-list">
    <b-overlay :show="entrustBusy">
      <portlet body-fit>
        <template slot="body">
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">تۇتۇلغان پۇل</h4>
                    <span class="kt-widget24__desc">ھازىرغىچە تۇتۇلغان پۇل سوممىسى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ total_success_money }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_order_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">مەغلۇپ بولغىنى</span>
                  <span class="kt-widget24__number">{{ count_fail_order }} دانە زاكاز</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">كېلىشىمدىكى ئەزالار</h4>
                    <span class="kt-widget24__desc">ھازىرقى نورمال ئەزالار سانى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ total_contract }} نەپەر</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_contract_progress" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تارىخى كېلىشىم ئەزا</span>
                  <span class="kt-widget24__number">{{ total_history_contract }} نەپەر</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">چېكىنگەن ئەزا</h4>
                    <span class="kt-widget24__desc">بارلىق چېكىنگەن ئەزا سانى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger">{{ total_deleted_contract }} نەپەر</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_deleted_contract_progress" variant="danger"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تارىخىي كېلىشىم ئەزا</span>
                  <span class="kt-widget24__number">{{ total_history_contract }} نەپەر</span>
                </div>
              </div>
            </b-col>
            <template v-if="yesterday_statistics_state">
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">تۈنۈگۈنكى نورمال زاكاز</h4>
                    <span class="kt-widget24__desc">تۈنۈگۈن بىر كۈن ئىچىدىكى نورمال پۇل تۇتۇلغان زاكاز</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ yesterday_success_order }} دانە</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="yesterday_success_order_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تۈنۈگۈنكى ئۇمۇمىي زاكاز</span>
                  <span class="kt-widget24__number">{{ yesterday_all_order }} دانە زاكاز</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">تۈنۈگۈن تۇتۇلغان پۇل</h4>
                    <span class="kt-widget24__desc">تۈنۈگۈن بىر كۈن ئىچىدىكى نورمال تۇتۇلغان پۇل</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ yesterday_success_money }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="yesterday_success_money" :max="yesterday_success_money" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تۈنۈگۈنكى ئۇمۇمىي زاكاز</span>
                  <span class="kt-widget24__number">{{ yesterday_all_order }} دانە زاكاز</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">تۈنۈگۈنكى نورمالسىز زاكاز</h4>
                    <span class="kt-widget24__desc">تۈنۈگۈن بىر كۈن ئىچىدىكى پۇل تۇتالمىغان زاكاز</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger">{{ yesterday_fail_order }} دانە</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="yesterday_fail_order_progress" variant="danger"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">تۈنۈگۈنكى ئۇمۇمىي زاكاز</span>
                  <span class="kt-widget24__number">{{ yesterday_all_order }} دانە زاكاز</span>
                </div>
              </div>
            </b-col>
            </template>
          </b-row>
        </template>
      </portlet>
      <paginate :implement-search="false" :refreshable="false" :meta="entrustsMeta" @change-page="onChangePage">
        <template slot="action">
          <b-input-group class="d-none d-sm-flex">
            <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            <b-input-group-prepend is-text><i class="fa fa-long-arrow-alt-left"></i></b-input-group-prepend>
            <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            <b-input-group-append>
              <b-button size="sm" :disabled="!formData.start_time || !formData.end_time" @click="onChangeTime" text="Button" variant="success">يېڭىلاش</b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </paginate>
      <b-row>
        <b-col sm="12" md="3" v-for="entrust in entrusts" :key="entrust.id">
          <portlet :title="` `" body-fluid body-class="px-3 pb-3 pt-0" head-no-border head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item :to="{name: 'entrust.show', params: {entrust_id: entrust.id}}">
                  <i class="fa fa-book-open"></i> كېلىشىم تەپسىلاتى
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDeleteContract(entrust.id)">
                  <i class="fa fa-unlink"></i> كېلىشىمنى بىكار قىلىش
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="onClickSwitchLocked(entrust.user)">
                  <i class="fa fa-user-lock"></i> قارا تىزىملىك ئۇچۇرى يېڭىلاش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar size="50px" text="!" :src="entrust.user && entrust.user.avatar" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ entrust.user && entrust.user.nickname || 'نامسىز' }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact" @click="onClickCopyUserId(entrust.user && entrust.user.id)">
                        <span class="kt-widget__label">ئەزالىق id نۇمۇرى</span>
                        <span class="kt-widget__data direction-rtl">{{entrust.user && entrust.user.id}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كېلىشىمگە قوشۇلغان ۋاقتى</span>
                        <span class="kt-widget__data direction-rtl">{{entrust.user && entrust.created_at}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> پۇل كولىنىش ۋاقتى </span>
                        <span class="kt-widget__data direction-rtl">{{entrust.next_time}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">مۇۋاپىقىيەتلىك كولانغان قېتىم سانى </span>
                        <span class="kt-widget__data">{{entrust.success_orders}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">مەغلۇپ بولغان قېتىم سانى </span>
                        <span class="kt-widget__data">{{entrust.fail_orders}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">چىكىنگەن قېتىم سانى </span>
                        <span class="kt-widget__data">{{entrust.user && entrust.user.entrust_close_times}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!entrusts.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، مۇناسىۋەتلىك كېلىشىم ئەزالىق ئۇچۇرى يوق ئىكەن." />
    </b-overlay>
    <user-switch-lock-modal v-model="switchLockedModalState" :busy="lockModalBusy" :lock-state="currentLockState" :user-id="currentUserId" @switching="onSwitching" @switched="onSwitched" @failed="onSwitchFailed" />
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import Paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import userSwitchLockModal from "@v@/components/modals/user.switch.lock.modal";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import entrustMixin from "@m@/entrust.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    components: { Error, Portlet, Paginate, Datetime, NoResult, userSwitchLockModal },
    mixins: [ formBusyMixin, entrustMixin, sweetAlertMixin, msgBoxConfirmMixin ],
    created() {
      this.getEntrustStatistics();
      this.getEntrusts();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'كېلىشىم ئەزالار ', route: {name: 'entrust.home'}});
      BreadCrumbs.addBreadCrumb({ title: "بارلىق كېلىشىم ئەزالار" });
    },
    computed: {
      total_order_progress(){
        let parts = this.entrustStatistics.count_success_order;
        let all = this.entrustStatistics.count_all_order;
        return (parts / all) * 100;
      },
      total_success_money(){
        return this.entrustStatistics.total_success_money;
      },
      count_fail_order(){
        return this.entrustStatistics.count_fail_order;
      },
      total_contract(){
        return this.entrustStatistics.count_add_contract;
      },
      total_history_contract(){
        return this.entrustStatistics.count_all_contract;
      },
      total_contract_progress(){
        let parts = this.entrustStatistics.count_add_contract;
        let all = this.entrustStatistics.count_all_contract;
        return (parts / all) * 100;
      },
      total_deleted_contract(){
        return this.entrustStatistics.count_deleted_contract;
      },
      total_deleted_contract_progress(){
        let parts = this.entrustStatistics.count_deleted_contract;
        let all = this.entrustStatistics.count_all_contract;
        return (parts / all) * 100;
      },

      yesterday_all_order(){
        return this.entrustStatistics.yesterday_all_order;
      },
      yesterday_success_order_progress(){
        let parts = this.entrustStatistics.yesterday_success_order;
        let all = this.entrustStatistics.yesterday_all_order;
        return (parts / all) * 100;
      },
      yesterday_success_order(){
        return this.entrustStatistics.yesterday_success_order;
      },
      yesterday_fail_order_progress(){
        let parts = this.entrustStatistics.yesterday_fail_order;
        let all = this.entrustStatistics.yesterday_all_order;
        return (parts / all) * 100;
      },
      yesterday_fail_order(){
        return this.entrustStatistics.yesterday_fail_order;
      },
      yesterday_success_money(){
        return this.entrustStatistics.yesterday_success_money;
      },
      yesterday_statistics_state(){
        return this.entrustStatistics.yesterday_statistics_state;
      },
    },
    data(){
      return {
        entrustBusy: false,
        formData: {
          start_time: '',
          end_time: ''
        },
        switchLockedModalState: false,
        currentLockState: false,
        currentUserId: null,
        lockModalBusy: false,
      };
    },
    methods: {
      onChangeTime(){
        let { start_time, end_time } = this.formData;
        this.getEntrustStatistics(start_time, end_time);
        this.getEntrusts(1, start_time, end_time);
      },
      onChangePage(e){
        let { page } = e;
        let { start_time, end_time } = this.formData;
        this.getEntrusts(page, start_time, end_time);
      },
      onClickCopyUserId(id){
        this.$clipboard(id);
        this.success({ message: 'ئەزالىق id نۇمۇرىنى كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى!' });
      },

      onClickSwitchLocked(user){
        let { is_locked, id } = user;
        this.setCurrentLockState(is_locked);
        this.setCurrentUserId(id);
        this.toggleSwitchLockedModalState();
      },
      setCurrentLockState(currentLockState = 0){
        this.currentLockState = currentLockState;
      },
      setCurrentUserId(currentUserId = null){
        this.currentUserId = currentUserId;
      },
      toggleSwitchLockedModalState(){
        this.switchLockedModalState = ! this.switchLockedModalState;
      },
      onSwitching(){
        this.showBusy('lockModalBusy');
      },
      onSwitched(){
        this.hideBusy('lockModalBusy');
        this.toggleSwitchLockedModalState();
        let { start_time, end_time, page } = this.formData;
        this.getEntrusts(page, start_time, end_time);
      },
      onSwitchFailed(){
        this.hideBusy('lockModalBusy');
      },
      onClickDeleteContract(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر كېلىشىمنى بىكار قىلىشنى جەزىملەشتۈرەمسىز ؟كېلىشىمنى بىكار قىلسىڭىز مەزكۇر ئەزادىن پۇل تۇتمايدۇ، خۇددى شۇ ئەزا ئۆز ئالدىغا بىكار قىلغان بىلەن ئوخشاش كۈچكە ئېگە!',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.manualDeleteContract(id)
                .then(()=>{
                  this.success({message: 'كېلىشىمنى بىكار قىلىش مۇۋاپىقىيەتلىك بولدى!'});
                  this.getEntrustStatistics();
                  this.getEntrusts();
                })
                .catch((result)=>{
                  console.log(result.response);
                  let { message } = result.response.data;
                  this.error({message});
                })
            }
          }
        });
      }
    }
  }
</script>

<style>
  .vdatetime-input {
    width: 100%;
    height: 100%;
    border: unset;
  }

  .kt-widget.kt-widget--user-profile-2 .kt-widget__head {
    margin-top: -46px;
  }
</style>
